import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Warehousing
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Warehousing
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Warehousing</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Warehousing.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    In today's fast-paced business environment, especially for a
                    large organization like yours, managing the balance between
                    items still in the production stage and available storage
                    space can be a complex challenge. At {companyname}, we offer
                    a range of solutions that can help optimize your operations
                    by efficiently managing your inventory through
                    well-organized warehousing facilities.
                  </p>

                  <p>
                    Our comprehensive services encompass a variety of options.
                    On one hand, we provide intracity and intercity
                    transportation services, ensuring seamless movement of goods
                    within and between cities. On the other hand, our expertise
                    extends to warehouse services and domestic courier
                    solutions, offering you a comprehensive logistics solution
                    under one roof.
                  </p>

                  <p>
                    The cornerstone and central pillar of our logistics
                    management system lies in our warehouse management
                    capabilities. {companyname} excels as a well-known
                    warehousing provider, catering to both import and export
                    shipments. Through our extensive network of partners, we
                    offer specialized solutions designed to accommodate
                    temperature-controlled goods, ensuring the integrity of
                    perishable items.
                  </p>

                  <p>
                    We go beyond just storage. Our comprehensive warehousing
                    services include repackaging and palletizing shipments, as
                    well as meticulous inventory management and control. This
                    meticulous attention to detail ensures that your products
                    are well-preserved and easily accessible when needed.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
