import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* About Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/About1.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
                <div className="bg-white experiences">
                  <h4 className="display-6">We Are</h4>
                  <h6 className="fw-bold">{companyname}</h6>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  About Us
                </h5>
                <h1 className="display-5 mb-2">
                  Navigating Challenges, Delivering Solutions
                </h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  At {companyname}, a leading freight and trading service
                  provider, we are guided by a firm belief that success in
                  today's constantly changing business world is achieved through
                  nurturing competitiveness and upholding unwavering standards
                  of excellence. We recognize that in a world shaped by dynamic
                  market forces, the harmonious fusion of these attributes is
                  crucial. Our commitment extends far beyond mere words, as we
                  relentlessly endeavor to provide services that embody
                  cost-effectiveness while upholding the exacting standards of
                  quality that define our approach.
                </p>
              </div>
            </div>
            <p className="mt-4 fs-5">
              With a dedicated focus on meeting the diverse needs of our
              customers, we have meticulously designed our range of services. At{" "}
              {companyname}, we recognize that each client comes with unique
              requirements, and our objective is to provide tailor-made
              solutions that not only meet these needs but also exceed
              expectations. From efficient Freight Forwarding services to
              comprehensive trading solutions, we aim to be the bridge that
              connects businesses to their goals.
            </p>

            <p className="fs-5">
              Our dedication to providing exceptional services is not just a
              statement; it's our driving force. Our team is comprised of
              experts who bring a wealth of industry experience, ensuring that
              our clients benefit from the highest level of expertise. The
              backbone of our operations is a commitment to transparency,
              reliability, and customer satisfaction. In an ever-connected world
              where businesses need to navigate complexities, {companyname}{" "}
              stands as a reliable partner, offering solutions that resonate
              with excellence, efficiency, and effectiveness.
            </p>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Services Start */}
      <div className="container-fluid services ">
        <div className="container text-center ">
          <div className="row g-5">
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-handshake  fa-3x text-primary" />
                  </div>
                </div>
                <h4>Flexibilty</h4>
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-lightbulb   fa-3x text-primary" />
                  </div>
                </div>
                <h4>Innovation</h4>
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-users  fa-3x text-primary" />
                  </div>
                </div>
                <h4>Team Work</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}

      <Footer />
    </>
  );
}
