import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Customs Clearance
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Customs Clearance
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">
                  Customs Clearance
                </h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Customs Clearance.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    For businesses engaged in import and export activities,
                    navigating the intricacies of customs clearance can often be
                    a daunting and time-consuming process. At {companyname}, we
                    prioritize seamless freight transportation, and thus, we
                    offer reliable and efficient customs clearing services.
                  </p>

                  <p>
                    Our expertise in handling the complex legal requirements and
                    procedures involved in customs clearance sets {companyname}{" "}
                    apart. Our team of professionals is well-equipped to handle
                    all types of shipments, whether they are transported by air,
                    sea, or land.
                  </p>

                  <p>
                    Our unwavering commitment to delivering unparalleled
                    customer service is evident in our customs clearing
                    services, which we hold in high regard. We offer a
                    comprehensive array of custom clearing services, including
                    cargo inspection, document preparation, import and export
                    procedures, and customs documentation. With our assistance,
                    businesses can have confidence that their shipments will
                    smoothly clear customs without delays or complications.
                  </p>

                  <p>
                    Recognizing that each organization has unique demands,{" "}
                    {companyname} provides specialized solutions tailored to
                    meet their specific needs. Our team of experts collaborates
                    closely with clients to gain a deep understanding of their
                    distinct requirements, enabling us to offer customized
                    solutions that expedite the customs clearance process.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
