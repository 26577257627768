import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Rail Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Rail Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Rail Freight</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Rail Freight.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    {companyname}, a prominent freight forwarding company, is
                    dedicated to delivering tailored logistics solutions to
                    businesses of all sizes. In addition to our renowned air and
                    ocean freight services, we extend our offerings to encompass
                    rail transport options, allowing us to comprehensively meet
                    the diverse demands of our clients.
                  </p>

                  <p>
                    Rail transportation holds significant importance in India as
                    a crucial method of cargo movement. It stands as an
                    economical and environmentally conscious solution for
                    transporting large quantities of goods over extensive
                    distances. The committed team of experts at {companyname}{" "}
                    possesses a wealth of expertise in rail transportation. Our
                    comprehensive solutions span from door-to-door delivery,
                    efficient handling, and secure storage of items to the
                    intricate processes of customs clearance and documentation.
                  </p>

                  <p>
                    Our extensive rail transportation network interconnects
                    India's major cities and industrial centers, efficiently
                    managed by {companyname}. Our array of rail freight
                    services encompasses cross-border operations, PTL and TL
                    freight, and reliable parcel delivery. Irrespective of
                    shipment size or complexity, our rail transportation
                    solutions are tailored to cater to the specific needs of
                    each client, ensuring seamless and efficient cargo movement.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
