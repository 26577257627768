import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header /> {/* Carousel Start */}
      <div className="container-fluid carousel px-0 mb-5 pb-5  w3-banner jarallax">
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "-1" }}
        >
          <source src="img/video.mp4" type="video/mp4" />
        </video>
      </div>
      {/* Carousel End */}

      {/* About Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/About1.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
                <div className="bg-white experiences">
                  <h4 className="display-6">We Are</h4>
                  <h6 className="fw-bold">{companyname}</h6>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  About Us
                </h5>
                <h1 className="display-5 mb-2">
                  Navigating Challenges, Delivering Solutions
                </h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  At {companyname}, a leading freight and trading service
                  provider, we are guided by a firm belief that success in
                  today's constantly changing business world is achieved through
                  nurturing competitiveness and upholding unwavering standards
                  of excellence. We recognize that in a world shaped by dynamic
                  market forces, the harmonious fusion of these attributes is
                  crucial. Our commitment extends far beyond mere words, as we
                  relentlessly endeavor to provide services that embody
                  cost-effectiveness while upholding the exacting standards of
                  quality that define our approach.
                </p>

                <Link
                  to="/About"
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Services Start */}
      <div className="container-fluid services ">
        <div className="container text-center ">

          <div className="row g-5">
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-handshake  fa-3x text-primary" />
                  </div>
                </div>
                <h4>Flexibilty</h4>
                
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-lightbulb   fa-3x text-primary" />
                  </div>
                </div>
                <h4>Innovation</h4>
                
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-users  fa-3x text-primary" />
                  </div>
                </div>
                <h4>Team Work</h4>
                
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* Services End */}
     
      {/* Blog Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              Services
            </h5>
            <h1 className="display-5">Explore Our Services</h1>
          </div>

          <div
            className="row wow fadeInUp"
            data-wow-delay=".5s"
          >
            <div className="blog-item col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s">
              <img
                src="img/Ocean Freight.jpg"
                className="img-fluid w-100 rounded-top"
                alt=""
              />
              <div className="rounded-bottom bg-light">
                <div className="d-flex justify-content-between p-4 pb-2">
                 
                </div>
                <div className="px-4 pb-0">
                  <h4>Ocean Freight</h4>
                  <p>
                    content
                  </p>
                </div>
                <div className="p-4 py-2 d-flex justify-content-between bg-primary rounded-bottom blog-btn">
                  <Link
                    to="/Ocean"
                    type="button"
                    className="btn btn-primary border-0"
                  >
                    Learn More
                  </Link>
                 
                </div>
              </div>
            </div>
            <div className="blog-item col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s">
              <img
                src="img/Air Freight.jpg"
                className="img-fluid w-100 rounded-top"
                alt=""
              />
              <div className="rounded-bottom bg-light">
                <div className="d-flex justify-content-between p-4 pb-2">
                 
                </div>
                <div className="px-4 pb-0">
                  <h4>Air Freight</h4>
                  <p>
                    content
                  </p>
                </div>
                <div className="p-4 py-2 d-flex justify-content-between bg-primary rounded-bottom blog-btn">
                  <Link
                    to="/Air"
                    type="button"
                    className="btn btn-primary border-0"
                  >
                    Learn More
                  </Link>
                 
                </div>
              </div>
            </div>
            <div className="blog-item col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s">
              <img
                src="img/Road Freight.jpg"
                className="img-fluid w-100 rounded-top"
                alt=""
              />
              <div className="rounded-bottom bg-light">
                <div className="d-flex justify-content-between p-4 pb-2">
                 
                </div>
                <div className="px-4 pb-0">
                  <h4>Road Freight</h4>
                  <p>
                    content
                  </p>
                </div>
                <div className="p-4 py-2 d-flex justify-content-between bg-primary rounded-bottom blog-btn">
                  <Link
                    to="/Road"
                    type="button"
                    className="btn btn-primary border-0"
                  >
                    Learn More
                  </Link>
                 
                </div>
              </div>
            </div>
            
          </div>
          <div
            className="row wow pt-4 fadeInUp"
            data-wow-delay=".5s"
          >
            <div className="blog-item col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s">
              <img
                src="img/Rail Freight.jpg"
                className="img-fluid w-100 rounded-top"
                alt=""
              />
              <div className="rounded-bottom bg-light">
                <div className="d-flex justify-content-between p-4 pb-2">
                 
                </div>
                <div className="px-4 pb-0">
                  <h4>Rail Freight</h4>
                  <p>
                    content
                  </p>
                </div>
                <div className="p-4 py-2 d-flex justify-content-between bg-primary rounded-bottom blog-btn">
                  <Link
                    to="/Rail"
                    type="button"
                    className="btn btn-primary border-0"
                  >
                    Learn More
                  </Link>
                 
                </div>
              </div>
            </div>
            <div className="blog-item col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s">
              <img
                src="img/Customs Clearance.jpg"
                className="img-fluid w-100 rounded-top"
                alt=""
              />
              <div className="rounded-bottom bg-light">
                <div className="d-flex justify-content-between p-4 pb-2">
                 
                </div>
                <div className="px-4 pb-0">
                  <h4>Customs Clearance</h4>
                  <p>
                    content
                  </p>
                </div>
                <div className="p-4 py-2 d-flex justify-content-between bg-primary rounded-bottom blog-btn">
                  <Link
                    to="/Customs"
                    type="button"
                    className="btn btn-primary border-0"
                  >
                    Learn More
                  </Link>
                 
                </div>
              </div>
            </div>
            <div className="blog-item col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s">
              <img
                src="img/Warehousing Service.jpg"
                className="img-fluid w-100 rounded-top"
                alt=""
              />
              <div className="rounded-bottom bg-light">
                <div className="d-flex justify-content-between p-4 pb-2">
                 
                </div>
                <div className="px-4 pb-0">
                  <h4>Warehousing Service</h4>
                  <p>
                    content
                  </p>
                </div>
                <div className="p-4 py-2 d-flex justify-content-between bg-primary rounded-bottom blog-btn">
                  <Link
                    to="/Warehousing"
                    type="button"
                    className="btn btn-primary border-0"
                  >
                    Learn More
                  </Link>
                 
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* Blog End */}
     
    
      {/* Team Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
            Trading Services
            </h5>
            <h1 className="display-5 w-50 mx-auto">Explore Our Trading Solutions</h1>
          </div>
          <div className="row g-5">
          <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="rounded team-item">
                <img
                  src="img/Fashion.jpg"
                  className="img-fluid w-100 rounded-top border border-bottom-0"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <div className="team-content bg-primary text-dark text-center py-3">
                    <span className="fs-4 fw-bold">Fashion</span>
                    <p className="text-muted mb-0">Trading Service</p>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="rounded team-item">
                <img
                  src="img/Food.jpg"
                  className="img-fluid w-100 rounded-top border border-bottom-0"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <div className="team-content bg-primary text-dark text-center py-3">
                    <span className="fs-4 fw-bold">Food</span>
                    <p className="text-muted mb-0">Trading Service</p>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="rounded team-item">
                <img
                  src="img/Chemical.jpg"
                  className="img-fluid w-100 rounded-top border border-bottom-0"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <div className="team-content bg-primary text-dark text-center py-3">
                    <span className="fs-4 fw-bold">Chemical</span>
                    <p className="text-muted mb-0">Trading Service</p>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="rounded team-item">
                <img
                  src="img/Furniture.jpg"
                  className="img-fluid w-100 rounded-top border border-bottom-0"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <div className="team-content bg-primary text-dark text-center py-3">
                    <span className="fs-4 fw-bold">Furniture</span>
                    <p className="text-muted mb-0">Trading Service</p>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="rounded team-item">
                <img
                  src="img/Oil and Gas.jpg"
                  className="img-fluid w-100 rounded-top border border-bottom-0"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <div className="team-content bg-primary text-dark text-center py-3">
                    <span className="fs-4 fw-bold">Oil and Gas</span>
                    <p className="text-muted mb-0">Trading Service</p>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="rounded team-item">
                <img
                  src="img/Retail.jpg"
                  className="img-fluid w-100 rounded-top border border-bottom-0"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <div className="team-content bg-primary text-dark text-center py-3">
                    <span className="fs-4 fw-bold">Retail</span>
                    <p className="text-muted mb-0">Trading Service</p>
                  </div>
                </Link>
              </div>
            </div>
            
           
          </div>
        </div>
      </div>
      {/* Team End */}
      <Footer />
    </>
  );
}
