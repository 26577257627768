import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Ocean Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ocean Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Ocean Freight</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Ocean Freight.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    {companyname} is a prominent player in the ocean freight
                    industry, operating strategically in major seaports. Our
                    expertise lies in providing tailored maritime freight
                    solutions that directly contribute to our clients' financial
                    prosperity. Backed by an extensive agency network and a
                    skilled workforce, we ensure smooth cargo movement while
                    addressing the unique requirements of each customer.
                  </p>

                  <p>
                    One of our key strengths is our adeptness in handling
                    breakbulk, over-dimensional, and heavy lift cargoes. We
                    understand the distinct nature of every shipment and offer
                    specialized solutions to guarantee the secure and timely
                    delivery of your cargo. Our experienced team is
                    well-prepared to tackle any challenges that may arise
                    throughout the shipping process.
                  </p>

                  <p>
                    With a global agency network spanning vital seaports, we
                    offer a wide range of dependable, flexible, and
                    cost-effective ocean freight services. Our strategic
                    partnerships with leading carriers and shipping lines enable
                    us to handle various types of cargo, including
                    full-container-load (FCL), less-than-container-load (LCL),
                    and breakbulk shipments.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
