import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid footer py-5 wow fadeIn"
        data-wow-delay=".3s"
      >
        <div className="container py-5">
          <div className="row g-4 footer-inner">
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">{companyname}</h4>
                <p>
                At {companyname}, a leading freight and trading service
                  provider, we are guided by a firm belief that success in
                  today's constantly changing business world is achieved through
                  nurturing competitiveness and upholding unwavering standards
                  of excellence.
                </p>
                <p className="mb-0">
                  <Link className to="#">
                  {companyname}
                  </Link>{" "}
                  © 2023 All Right Reserved.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">Usefull Link</h4>
                <div className="d-flex flex-column align-items-start">
                  <Link className="btn btn-link ps-0" to="/Home">
                    <i className="fa fa-check me-2" />
                    Home
                  </Link>
                  <Link className="btn btn-link ps-0" to="/About">
                    <i className="fa fa-check me-2" />
                    About Us
                  </Link>
                 
                  <Link className="btn btn-link ps-0" to="/Trading">
                    <i className="fa fa-check me-2" />
                    Trading Services
                  </Link>
                  <Link className="btn btn-link ps-0" to="/Contact">
                    <i className="fa fa-check me-2" />
                    Contact
                  </Link>
                  <Link className="btn btn-link ps-0" to="/Getquote">
                    <i className="fa fa-check me-2" />
                    Getquote
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">Services Link</h4>
                <div className="d-flex flex-column align-items-start">
                <Link className="btn btn-link ps-0" to="/Ocean">
                    <i className="fa fa-check me-2" />
                    Ocean Freight
                  </Link>
                  <Link className="btn btn-link ps-0" to="/Air">
                    <i className="fa fa-check me-2" />
                    Air Freight
                  </Link>
                  <Link className="btn btn-link ps-0" to="/Road">
                    <i className="fa fa-check me-2" />
                    Road Freight
                  </Link>
                  <Link className="btn btn-link ps-0" to="/Rail">
                    <i className="fa fa-check me-2" />
                    Rail Freight
                  </Link>
                  <Link className="btn btn-link ps-0" to="/Customs">
                    <i className="fa fa-check me-2" />
                    Customs Clearance
                  </Link>
                  <Link className="btn btn-link ps-0" to="/Warehousing">
                    <i className="fa fa-check me-2" />
                    Warehousing Service
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">Contact Us</h4>
                <Link
                  to
                  className="btn btn-link w-100 text-start ps-0 pb-3 border-bottom rounded-0"
                >
                  <i className="fa fa-map-marker-alt me-3" />
                {companyaddress}
                </Link>
                <Link
                  to
                  className="btn btn-link w-100 text-start ps-0 py-3 border-bottom rounded-0"
                >
                  <i className="fa fa-phone-alt me-3" />
                  {companynumber}
                </Link>
                <Link
                  to
                  className="btn btn-link w-100 text-start ps-0 py-3 border-bottom rounded-0"
                >
                  <i className="fa fa-envelope me-3" />
                  {companyemail}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
     
    </>
  );
};

export default Footer;
