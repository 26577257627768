import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              Our Services
            </h5>
            <h1 className="display-5 w-50 mx-auto">Air Freight</h1>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Air Freight.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    {companyname} has established itself as a prominent player
                    in the air freight forwarding industry, specializing in the
                    meticulous handling of perishable goods and general cargo.
                    Our reputation is built upon delivering efficient and
                    dependable air freight services, boasting an impressive
                    daily cargo handling capacity from India to destinations
                    worldwide. With an extensive network, we gain valuable
                    insights into the unique challenges faced by businesses in
                    the realm of air freight transportation.
                  </p>

                  <p>
                    What truly distinguishes us is our expertise in managing
                    temperature-controlled shipments, encompassing a diverse
                    range of items such as pharmaceuticals, vaccines, bulk
                    drugs, fresh produce, delicate flowers, electronics,
                    garments, and tissue culture. Recognizing the critical
                    importance of handling such shipments with precision and
                    care, we have assembled a team of professionals who excel in
                    managing all types of perishable cargo.
                  </p>

                  <p>
                    At {companyname}, we acknowledge that a one-size-fits-all
                    approach doesn't suffice in the dynamic world of air
                    freight. We firmly believe that each client is unique, which
                    is why we offer tailor-made solutions that align perfectly
                    with the specific needs of every individual client. Our
                    enthusiastic team of specialists is dedicated to crafting
                    innovative and adaptable air freight solutions that
                    consistently set new benchmarks in the industry.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
