import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Trading Solutions
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Trading Solutions
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/Fashion.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  Trading Services
                </h5>
                <h1 className="display-5 mb-2">Fashion</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                <p>The fashion industry is characterized by rapidly evolving trends and ever-shortening product life cycles. At our core, we recognize the critical importance of keeping pace with this swift rhythm. As the landscape of fashion constantly reshapes itself, we stand ready to meet its demands head-on. We understand that success in this industry hinges on the pillars of speed, quality, flexibility, and the ability to seamlessly adapt to change.</p>

<p>Our approach to fashion logistics is not just a mere adjustment – it's a complete transformation. We have realigned our resources to align with the unique demands of the fashion sector. At every step of the journey, from sourcing to distribution, we have fine-tuned our operations to cater to the dynamic nature of fashion. Our agility isn't just a response; it's a proactive strategy to ensure that our clients are always ahead of the curve.</p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  Trading Services
                </h5>
                <h1 className="display-5 mb-2">Food</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                <p>The Food industry operates in a realm where the life cycles of products are constantly shrinking year after year. We recognize the intrinsic nature of this phenomenon, where trends evolve swiftly and consumer preferences shift rapidly. In response to these dynamics, we have not only stayed attuned but also revolutionized our approach to accommodate the industry's demands. Our awareness of the essential factors - speed, quality, flexibility, and the capacity to embrace change - underscores our commitment to Food logistics.</p>

<p>Our resolute commitment to the Food sector has prompted us to reshape our resources entirely. We understand that success in this industry necessitates not only reacting to market trends but anticipating them. By aligning our operations with the industry's pace, we have forged an operational model that thrives on agility. We have evolved from being just a logistics provider to becoming an essential link in your supply chain, enabling you to keep up with the relentless cadence of the Food world.</p>
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/Food.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/Chemical.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  Trading Services
                </h5>
                <h1 className="display-5 mb-2">Chemical</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  <p>
                    Within the realm of specialized chemicals, we boast a
                    dedicated team equipped with a wealth of expertise in
                    handling chemical products. Our team is fortified with
                    continuous training in the intricacies of dangerous goods
                    and transport regulations, ensuring that your company's
                    needs for a secure and hazard-free supply chain are met
                    comprehensively.
                  </p>

                  <p>
                    Dealing with hazardous materials demands an unparalleled
                    level of precision and caution. Our specialized chemicals
                    team is well-versed in the nuances of transporting dangerous
                    goods. Whether it's volatile substances or materials with
                    specific handling requirements, we ensure that every aspect
                    of the transportation process adheres to the highest safety
                    standards.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  Trading Services
                </h5>
                <h1 className="display-5 mb-2">Furniture</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  <p>
                    Furniture manufacturing, importing, and retailing are
                    diverse fields, each with their unique characteristics.
                    However, a common thread that unites them is the presence of
                    logistics challenges. Across the industry, transportation
                    complexities arise, impacting the smooth flow of operations.
                    From production delays to delivery schedule disruptions,
                    even the most efficient companies encounter hurdles.
                    Additionally, the global reach capabilities often fall short
                    for furniture businesses, regardless of their size.
                  </p>

                  <p>
                    At {companyname}, addressing these challenges is
                    part of our daily routine. This is particularly true in the
                    furniture market, where we've accumulated three decades of
                    combined experience catering to the industry's unique
                    demands. Over the years, our commitment to delivering
                    positive outcomes has earned us a solid reputation in the
                    furniture sector.
                  </p>
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/Furniture.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/Oil and Gas.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  Trading Services
                </h5>
                <h1 className="display-5 mb-2">Oil and Gas</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  <p>
                    In the realm of Oil & Gas supply chains, safety takes on
                    paramount importance. At {companyname}, we
                    recognize the criticality of this factor and stand ready to
                    provide comprehensive assistance throughout the entire
                    process. Our services are meticulously tailored to address
                    the unique needs of the industry. From the secure and
                    efficient transportation of oil & gas products to the
                    intricate coordination of international projects, we offer
                    comprehensive solutions that span the globe.
                  </p>

                  <p>
                    Our team is equipped with technical expertise that remains
                    at your service at all times. With a dedicated group of
                    professionals, we are well-equipped to oversee the entire
                    supply chain. Our ultimate objective is to align with your
                    specific requirements, offering unwavering support across
                    every facet of your operations.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                  Trading Services
                </h5>
                <h1 className="display-5 mb-2">Retail</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  <p>
                    Collaboration is at the heart of our approach. We engage
                    closely with your vendors, actively monitoring production
                    timelines and ensuring timely availability. Our focus is on
                    facilitating a swift and seamless turnover, starting from
                    the manufacturer's doorstep and culminating at the port.
                  </p>

                  <p>
                    Together with our clients, we cultivate ongoing value by
                    setting up and monitoring key performance indicators. We
                    believe in the power of data-driven insights to optimize
                    operations. Periodic customer business reviews allow us to
                    assess progress and make strategic adjustments as needed.
                  </p>
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark" />
                <div className="rotate-right bg-dark" />
                <img
                  src="img/Retail.jpg"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      <Footer />
    </>
  );
}
