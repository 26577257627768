import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid topbar-top bg-primary">
        <div className="container">
          <div className="d-flex justify-content-between topbar py-2">
            <div className="d-flex align-items-center flex-shrink-0 topbar-info">
              <Link to="#" className="me-4 text-secondary">
                <i className="fas fa-phone-alt me-2 text-dark" />
                {companynumber}
              </Link>
              <Link to="#" className="text-secondary">
                <i className="fas fa-envelope me-2 text-dark" />
                {companyemail}
              </Link>
            </div>

            <div className="d-flex align-items-center justify-content-center topbar-icon">
              <Link to="/Privacy" className="me-4 text-secondary">
                Privacy Policy
              </Link>
              <Link to="/Terms" className="text-secondary">
                Terms
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <div className="container-fluid bg-dark">
        <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-lg-0">
            <Link to="/Home" className="navbar-brand">
              <img src="img/logo.png" style={{ height: "90px" }}></img>
            </Link>
            <button
              className="navbar-toggler bg-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars text-dark" />
            </button>
            <div className="collapse navbar-collapse me-n3" id="navbarCollapse">
              <div className="navbar-nav ms-auto">
                <Link to="/Home" className="nav-item nav-link active">
                  Home
                </Link>
                <Link to="/About" className="nav-item nav-link">
                  About Company
                </Link>

                <div className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Services We Provide
                  </Link>
                  <div className="dropdown-menu m-0 bg-primary">
                    <Link to="/Ocean" className="dropdown-item">
                      Ocean Freight
                    </Link>
                    <Link to="/Air" className="dropdown-item">
                      Air Freight
                    </Link>
                    <Link to="/Road" className="dropdown-item">
                      Road Freight
                    </Link>
                    <Link to="/Rail" className="dropdown-item">
                      Rail Freight
                    </Link>
                    <Link to="/Customs" className="dropdown-item">
                      Customs Clearance
                    </Link>
                    <Link to="/Warehousing" className="dropdown-item">
                      Warehousing Service
                    </Link>
                  </div>
                </div>
                <div className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Trading Services
                  </Link>
                  <div className="dropdown-menu m-0 bg-primary">
                    <Link to="/Trading" className="dropdown-item">
                    Fashion
                    </Link>
                    <Link to="/Trading" className="dropdown-item">
                    Food
                    </Link>
                    <Link to="/Trading" className="dropdown-item">
                    Furniture
                    </Link>
                    <Link to="/Trading" className="dropdown-item">
                    Oil and Gas
                    </Link>
                    <Link to="/Trading" className="dropdown-item">
                    Retail
                    </Link>
                  </div>
                </div>
                <Link to="/Contact" className="nav-item nav-link">
                  Contact
                </Link>
                <Link
                  to="/Getquote"
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 ms-2 "
                >
                  Request For Quote
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* Navbar End */}
    </>
  );
};

export default Header;
