import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Road Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Road Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Road Freight</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Road Freight.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    {companyname} is dedicated to delivering standardized
                    surface transportation services with unwavering confidence
                    in the safe handling of cargo. Our foremost objective is to
                    ensure the impeccable condition of goods, leading to
                    complete client satisfaction. We maintain the highest levels
                    of integrity and commitment, resulting in services that are
                    not only effective but also highly trustworthy.
                  </p>

                  <p>
                    Our road transport services have garnered acclaim from
                    clients who consistently value our rapid responsiveness to
                    their needs. In the realm of transporting items from one
                    location to another, we exclusively employ top-tier
                    vehicles, positioning us as a premier provider of road
                    transport services in India.
                  </p>

                  <p>
                    Our standards for land transport services are thoughtfully
                    aligned with the evolving demands of businesses and
                    industries. We prioritize the security of our clients by
                    offering precisely defined transit times, allowing them to
                    effectively plan their finances and maintain precise control
                    over the flow of their goods.
                  </p>

                  <p>
                    Specializing in the secure movement of our clients' goods,
                    our fleet of container trailers ensures complete safety. Our
                    prompt and reliable services are built on the core
                    principles of care and excellence. Our business ethos
                    revolves around delivering goods in pristine condition. What
                    sets us apart is our customer-centric approach, where
                    on-time delivery and safety are paramount. Over time, we
                    have consistently provided clients with dependable,
                    efficient, and expert services, reflecting our unwavering
                    commitment to their satisfaction.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
